import React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Stack,
  Heading,
  HStack,
  Center,
  Container,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Link } from "gatsby";

import Summary from "./Summary";

const IntegrationsList = (props) => {
  const { articles, description } = props;

  if (!articles || articles.length === 0)
    return (
      <Stack borderWidth="1px" maxWidth="7xl" borderRadius="lg" p="10vh">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/journals/biology">
              Biology
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/journals/biology/categories">
              Categories
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Integrations</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Heading size="lg" color="gray">
          There are no articles to display
        </Heading>
      </Stack>
    );

  const integrationArticles = articles.filter(
    (a) => a.integrations && a.integrations.length > 0
  );

  return (
    <Center paddingTop="100px">
      <Stack borderWidth="1px" maxWidth="7xl" borderRadius="lg" p="2em">
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/journals/biology">
              Biology
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/journals/biology/categories">
              Categories
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Integrations</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <HStack>
          <Heading size="lg" color="gray">
            Category: Integrations
          </Heading>
          <Container maxW="3xl">
            <Text>{description}</Text>
          </Container>
        </HStack>

        {integrationArticles.map((article, index) => {
          const {
            doi,
            history,
            submissionTypes,
            authors,
            figure,
            title,
            integrations,
          } = article;
          let figureFile;

          if (figure && figure.url) {
            const extension = figure.url.split(".").pop();
            const articleId =
              doi &&
              doi.includes("/") &&
              doi.split("/").pop().replace(/\./g, "-");

            figureFile = `/static/thumbnails/${articleId}.${extension}`;
          }

          return (
            <Container
              overflowX="hidden"
              overflowY="hidden"
              maxWidth="100%"
              padding={3}
              backgroundColor="gray.100"
              borderRadius="lg"
              key={`integration-${index}`}
            >
              <Summary
                authors={authors}
                categories={submissionTypes}
                doi={doi}
                figure={figureFile}
                publishedDate={history.published}
                title={title}
                isList
              />
              <Wrap>
                {integrations
                  .map((i) => articles.find((a) => a.id === i.manuscriptId))
                  .filter((a) => a)
                  .map((a) => {
                    let integrationFigureFile;
                    if (a.figure && a.figure.url) {
                      const extension = a.figure.url.split(".").pop();
                      const articleId =
                        a.doi &&
                        a.doi.includes("/") &&
                        a.doi.split("/").pop().replace(/\./g, "-");

                      integrationFigureFile = `/static/thumbnails/${articleId}.${extension}`;
                    }

                    return (
                      <WrapItem key={`summary-${a.doi}`}>
                        <Summary
                          authors={a.authors}
                          categories={a.submissionTypes}
                          doi={a.doi}
                          publishedDate={a.history.published}
                          title={a.title}
                          figure={integrationFigureFile}
                        />
                      </WrapItem>
                    );
                  })}
              </Wrap>
            </Container>
          );
        })}
      </Stack>
    </Center>
  );
};

IntegrationsList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      authors: PropTypes.arrayOf(
        PropTypes.shape({
          firstName: PropTypes.string,
          lastName: PropTypes.string,
        })
      ),
      categories: PropTypes.arrayOf(PropTypes.string),
      doi: PropTypes.string,
      figure: PropTypes.shape({ url: PropTypes.string }),
      pdfUrl: PropTypes.string,
      publishedDate: PropTypes.string,
      reviewedBy: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};
IntegrationsList.defaultProps = {
  articles: [],
};

export default IntegrationsList;
