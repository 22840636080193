import React from "react";

import IntegrationsList from "../ui/articleList/IntegrationsList";
import Layout from "../components/Layout";

const IntegrationsIndexTemplate = (props) => {
  const { pageContext } = props;
  const { articles, description } = pageContext;

  const articleList = (
    <IntegrationsList articles={articles} description={description} />
  );

  return <Layout children={articleList} />;
};

export default IntegrationsIndexTemplate;
